@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./datepicker-styles.css");
@import url("./calendar-styles.css");

/*********************************************************
 * Estilos personalizados para la tabla
 ********************************************************/
 .table-large thead th div {
  font-size: 12px !important;
 }

.table-large th, .table-large td {
  padding: 0.5rem !important;
  font-size: 12px;
}

.table-large .rizzui-badge {
  padding: 0 0.2rem !important;
  font-size: 12px;
}

/*********************************************************
 * Estilos personalizados para la paginación
 ********************************************************/
.pagination .svg-icon svg {
  width: 18px;
  height: 18px;
}

.pagination-active {
  @apply bg-primary text-white border-primary hover:bg-opacity-90 focus:ring-2 focus:ring-primary focus:border-primary;
}

.picker {
  display: flex;
  align-items: flex-end;
  position: relative;
}

.swatch {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.popover {
  position: absolute;
  top: calc(100% + 2px);
  right: 0%;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  z-index: 15;
}

/*********************************************************
 * Estilos personalizados para los elementos disabled
 ********************************************************/
input[type="checkbox"]:disabled {
  background-color: rgba(195, 196, 199, 0.7) !important;
  border-color: rgb(229 231 235 / 0.9) !important;
}

/*********************************************************
 * Estilos personalizados para los nodos del árbol
 ********************************************************/
.networkWrapper {
  border-top: 1px dashed #ccc;
}

#treeWrapper {
  width: 100%;
  height: 75vh;
}

.node__root>circle {
  fill: #04abd8;
}

.node__branch>circle {
  fill: #d4edd9;
}

.node__leaf>circle {
  fill: #00538c;
  /* r: 40; */
}

.node__leaf {
  background-color: #00538c !important;
}

.rd3t-label {
  font-size: 15px;
  cursor: default;
}

.rd3t-label__title {
  fill: #000;
  stroke: none;
  font-weight: bolder;
}

.rd3t-label__title-disabled {
  fill: #000;
  stroke: none;
  font-weight: 400;
}

.rd3t-label__attributes {
  font-size: 13px !important;
  font-weight: 300 !important;
}

.asideTreeForm {
  box-shadow: -5px 0 5px -5px #a5a5a5;
}

/*********************************************************
 * Estilos personalizados para el input de teléfono
 ********************************************************/
input[type="tel"] {
  background-color: transparent;
  border-color: transparent !important;
  border-width: 0px !important;
  padding: 0;
}

input[type="tel"]:hover {
  border-color: rgb(0, 172, 216) !important;
  --tw-border-opacity: 1;
}

input[type="tel"]:focus {
  border-color: transparent !important;
  --tw-ring-shadow: none;
}

/*********************************************************
 * Estilos personalizados para el tooltip
 ********************************************************/
.tooltip-container {
  text-align: justify;
  max-width: 400px;
  white-space: normal;
  word-wrap: break-word;
}

/*********************************************************
 * Estilos personalizados para el checkbox
 ********************************************************/
.rizzui-checkbox-container {
  display: flex;
  align-items: start;
}

/*********************************************************
* Estilos personalizados para el formulario de contrato
*********************************************************/
.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  visibility: hidden;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

/*********************************************************
 * Estilos personalizados para ocultar menú lateral
 ********************************************************/
.slide-container {
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}

.slide-enter {
  transform: translateX(0);
  opacity: 1;
  width: 30%;
  margin-right: 0;
}

.slide-exit {
  transform: translateX(100%);
  opacity: 0;
  width: 0;
  margin-right: -16px;
}

@media (max-width: 1024px) {
  .slide-enter {
    width: 100%;
  }
}

.content-wrapper {
  transition: all 0.5s ease-in-out;
}

.content-visible {
  opacity: 1;
  transform: translateX(0);
}

.content-hidden {
  opacity: 0;
  transform: translateX(100%);
}

/*********************************************************
* Custom scrollbar
 ********************************************************/
@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 18px;
    height: 20px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed00;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    @apply bg-primary-400;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    @apply bg-primary-500;
  }

  /* SCROLLBAR THIN */
  .scrollbar-thin::-webkit-scrollbar {
    width: 13px;
    height: 10px;
  }

  .scrollbar-thin::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed00;
  }

  .scrollbar-thin::-webkit-scrollbar-thumb {
    @apply bg-primary-400;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  .scrollbar-thin::-webkit-scrollbar-thumb:hover {
    @apply bg-primary-500;
  }

}