/*********************************************************
 * Estilos personalizados para el Date Picker
 ********************************************************/

.react-datepicker-popper .react-datepicker {
  @apply bg-white text-gray-600 font-inter dark:border-gray-300 dark:bg-gray-100;
}

/* month container */
.react-datepicker .react-datepicker__month-container {
  @apply px-3;
}

/* time container */
.react-datepicker .react-datepicker__time-container {
  @apply w-auto border-l-0 pr-3.5;
}

.react-datepicker-popper
  .react-datepicker__time-container
  .react-datepicker__time {
  @apply bg-gray-50 dark:bg-gray-200;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  @apply dark:bg-gray-300;
}

/* header */
.react-datepicker .react-datepicker__header {
  @apply font-lexend border-b-gray-0 bg-gray-0 dark:border-transparent dark:bg-gray-100;
}

/* current month name in header */
.react-datepicker .react-datepicker-year-header {
  @apply dark:text-gray-700;
}

.react-datepicker .react-datepicker__current-month {
  @apply mb-3 text-base font-medium dark:text-gray-700;
}

/* sun-sat day names in header */
.react-datepicker .react-datepicker__day-names div {
  @apply m-1.5 text-sm text-gray-700;
}

/* previous month button */
.react-datepicker .react-datepicker__navigation--previous {
  @apply ml-6 rtl:mr-6;
}

/* next month button */
.react-datepicker .react-datepicker__navigation--next {
  @apply mr-6 rtl:ml-6;
}

/* icon in previous month button */
.react-datepicker .react-datepicker__navigation-icon--previous {
  @apply right-0.5;
}

/* icon in next month button */
.react-datepicker .react-datepicker__navigation-icon--next {
  @apply left-0.5;
}

/* each day */
.react-datepicker .react-datepicker__day {
  @apply m-1.5 text-sm leading-7 text-gray-700;
  @apply hover:rounded-full hover:bg-gray-100 hover:text-gray-900;
}

/* outside month */
.react-datepicker .react-datepicker__day--outside-month {
  @apply text-gray-500;
}

/* keyboard selected */
.react-datepicker .react-datepicker__day--keyboard-selected {
  @apply bg-gray-0;
}

/* today */
.react-datepicker .react-datepicker__day--today {
  @apply rounded-full border border-gray-300 bg-gray-0 leading-[26px] text-gray-900;
  @apply hover:border-gray-600 hover:bg-gray-0;
}

/* while selecting */
.react-datepicker div.react-datepicker__day--in-selecting-range {
  @apply rounded-full bg-gray-200 text-gray-900;
}

.react-datepicker div.react-datepicker__year-text--in-selecting-range {
  @apply bg-gray-200 text-gray-900;
}

.react-datepicker
  div.react-datepicker__year-text--in-selecting-range.react-datepicker__year-text--disabled {
  @apply bg-transparent text-gray-300;
}

/* in range */
.react-datepicker .react-datepicker__day--in-range {
  @apply rounded-full bg-primary-200 text-gray-900;
}

.react-datepicker .react-datepicker__year-text--in-range {
  @apply bg-primary-200 text-gray-900;
}

/* selected */
.react-datepicker .react-datepicker__day--range-start,
.react-datepicker .react-datepicker__day--range-end,
.react-datepicker .react-datepicker__day--selected {
  @apply rounded-full border-none bg-primary-900 font-normal leading-7 text-gray-0;
  @apply hover:bg-gray-900/80 hover:text-gray-0;
}

.react-datepicker .react-datepicker__year-text--range-end {
  @apply border-none bg-gray-900 font-normal text-gray-0 hover:bg-gray-900/80 hover:text-gray-0;
}

/* time list */
.react-datepicker .react-datepicker__time-list {
  @apply !h-[247px];
}

/* time item */
.react-datepicker .react-datepicker__time-list-item {
  @apply my-2 rounded text-sm text-gray-500;
  @apply hover:bg-gray-100 hover:text-gray-900;
}

/* selected time item */
.react-datepicker .react-datepicker__time-list-item--selected {
  @apply !bg-primary-100 !font-medium !text-gray-900;
}

/* time only box */
.react-datepicker-popper .react-datepicker-time__header {
  @apply text-gray-700;
}

.react-datepicker-popper
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 90px;
}

.react-datepicker--time-only
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  @apply w-auto;
}

/* time only item */
.react-datepicker--time-only .react-datepicker__time-list-item {
  @apply rounded-none;
}

/* month picker text */
.react-datepicker .react-datepicker__month-text {
  @apply w-0 px-5 py-1.5;
}

/* keyboard selected month in month picker */
.react-datepicker .react-datepicker__month-text--keyboard-selected {
  @apply bg-gray-100 text-gray-900;
}

/* month in range */
.react-datepicker .react-datepicker__month--in-range {
  @apply bg-gray-200 text-gray-700;
}

/* hover on selected months in range */
.react-datepicker
  .react-datepicker__month-text.react-datepicker__month--in-range:hover {
  @apply bg-gray-200 text-gray-900;
}

/* selected month in range */
.react-datepicker .react-datepicker__month--range-start,
.react-datepicker .react-datepicker__month--range-end,
.react-datepicker .react-datepicker__month--selected {
  @apply bg-primary-900 font-normal text-gray-0;
}

/* hover on selected range start and end month */
.react-datepicker
  .react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker
  .react-datepicker__month-text.react-datepicker__month--range-start:hover,
.react-datepicker
  .react-datepicker__month-text.react-datepicker__month--range-end:hover {
  @apply bg-primary-900/80 text-gray-0;
}

/* year wrapper in year picker */
.react-datepicker .react-datepicker__year-wrapper {
  @apply inline-block max-w-[220px];
}

/* year text in year picker */
.react-datepicker .react-datepicker__year-text {
  @apply w-auto px-5 py-1.5 text-gray-600 dark:hover:text-gray-100;
}

.react-datepicker .react-datepicker__year-text--range-end {
  @apply text-gray-0;
}

/* keyboard selected year in year picker */
.react-datepicker .react-datepicker__year-text--keyboard-selected {
  @apply bg-gray-200 text-gray-900;
  @apply hover:bg-gray-300 hover:text-gray-900;
}

/* selected year & month in year picker */
.react-datepicker
  .react-datepicker__year-text.react-datepicker__year-text--selected,
.react-datepicker
  .react-datepicker__month-text.react-datepicker__month-text--selected {
  @apply bg-primary-900 text-gray-0;
  @apply hover:bg-primary-900/80 hover:text-gray-0;
}

/* year and month dropdown arrow */
.react-datepicker .react-datepicker__year-read-view--down-arrow,
.react-datepicker .react-datepicker__month-read-view--down-arrow {
  @apply top-[5px] h-[7px] w-[7px] border-r-[1.5px] border-t-[1.5px] border-gray-400;
}

/* sub-header containing year and month dropdown */
.react-datepicker
  .react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown
  ~ .react-datepicker__header__dropdown {
  @apply my-2 grid grid-cols-2 divide-x divide-gray-300 text-sm;
}

/* month and year dropdown button in sub-header */
.react-datepicker .react-datepicker__month-dropdown-container--scroll,
.react-datepicker .react-datepicker__year-dropdown-container--scroll {
  @apply inline-flex justify-center;
}

/* month and year dropdown */
.react-datepicker .react-datepicker__year-dropdown,
.react-datepicker .react-datepicker__month-dropdown {
  @apply top-auto w-2/5 border border-gray-100 bg-gray-0 shadow-md;
}

/* year dropdown */
.react-datepicker .react-datepicker__year-dropdown {
  @apply h-80;
}

/* month dropdown */
.react-datepicker .react-datepicker__month-dropdown {
  @apply py-3;
}

/* month and year option */
.react-datepicker .react-datepicker__month-option,
.react-datepicker .react-datepicker__year-option {
  @apply my-1 py-1 text-sm text-gray-600;
  @apply hover:bg-gray-100 hover:text-gray-900;
}

/* first and last type of month and year option */
.react-datepicker .react-datepicker__month-option:first-of-type,
.react-datepicker .react-datepicker__month-option:last-of-type,
.react-datepicker .react-datepicker__year-option:first-of-type,
.react-datepicker .react-datepicker__year-option:last-of-type {
  @apply rounded-none;
}

/* selected month and year in dropdown */
.react-datepicker .react-datepicker__month-option--selected_month,
.react-datepicker .react-datepicker__year-option--selected_year {
  @apply bg-primary-200 text-gray-900;
  @apply [&>span]:hidden;
}

.react-datepicker .react-datepicker__day:hover,
.react-datepicker .react-datepicker__month-text:hover,
.react-datepicker .react-datepicker__quarter-text:hover,
.react-datepicker .react-datepicker__year-text:hover {
  @apply bg-gray-200 text-gray-900;
}

/* disabled item */
.react-datepicker .react-datepicker__day--disabled {
  @apply cursor-not-allowed text-gray-300 hover:bg-transparent hover:text-gray-300 dark:hover:text-gray-300;
}

.react-datepicker .react-datepicker__year-text--disabled {
  @apply cursor-not-allowed text-gray-300 hover:bg-transparent dark:hover:text-gray-300;
}

/*  Event Calendar Date Picker Styles */

.date-picker-event-calendar .react-datepicker {
  @apply dark:bg-gray-200;
}

.date-picker-event-calendar .react-datepicker__day-names {
  @apply max-sm:flex;
}

.date-picker-event-calendar
  .react-datepicker__day-names
  .react-datepicker__day-name {
  @apply max-sm:m-0.5 max-sm:w-[calc(100%_/_7)] max-sm:text-xs;
}
.date-picker-event-calendar .react-datepicker__navigation,
.date-picker-event-calendar .react-datepicker__navigation span::before {
  @apply dark:!border-gray-500;
}

.date-picker-event-calendar
  .react-datepicker__navigation.react-datepicker__navigation--next.react-datepicker__navigation--next--with-time {
  @apply max-sm:!-me-4 sm:!me-11;
}

.date-picker-event-calendar
  .react-datepicker__navigation.react-datepicker__navigation--previous {
  @apply max-sm:ms-1;
}
.date-picker-event-calendar
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker {
  @apply max-sm:flex;
}

.date-picker-event-calendar
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container {
  @apply max-sm:px-0;
}

.date-picker-event-calendar
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month {
  @apply max-sm:mx-0;
}

.date-picker-event-calendar
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__header {
  @apply dark:bg-[unset];
}
.date-picker-event-calendar
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day {
  @apply max-sm:m-1 max-sm:flex max-sm:aspect-square max-sm:w-[23.3px] max-sm:items-center max-sm:justify-center max-sm:text-xs;
}

.date-picker-event-calendar
  .react-datepicker__tab-loop
  .react-datepicker-popper
  .react-datepicker
  .react-datepicker__month
  .react-datepicker__week {
  @apply max-sm:mx-0.5 max-sm:flex;
}

.date-picker-event-calendar
  .react-datepicker__time-container
  .react-datepicker__header {
  @apply dark:bg-[unset];
}

.date-picker-event-calendar .react-datepicker__time-container {
  @apply max-sm:p-1 max-sm:ps-0;
}

.date-picker-event-calendar
  .react-datepicker__time-container
  .react-datepicker__time {
  @apply dark:bg-gray-300/30;
}

.date-picker-event-calendar
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  @apply max-sm:!w-[unset];
}

.date-picker-event-calendar
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  .react-datepicker__time-list::-webkit-scrollbar {
  @apply w-1 bg-gray-100;
}

.date-picker-event-calendar
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  .react-datepicker__time-list::-webkit-scrollbar-thumb {
  @apply rounded-full bg-gray-400;
}

.date-picker-event-calendar
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  .react-datepicker__time-list {
  @apply overflow-x-hidden;
}

.date-picker-event-calendar .react-datepicker__time-container {
  @apply max-sm:!pb-1;
}

.date-picker-event-calendar
  .react-datepicker__time-container
  .react-datepicker-time__header {
  @apply font-medium;
}

.date-picker-event-calendar .react-datepicker__week .react-datepicker__day {
  @apply dark:hover:bg-gray-400;
}

.date-picker-event-calendar .react-datepicker__week .react-datepicker__day {
  @apply !rounded-full;
}

.date-picker-event-calendar
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--selected {
  @apply dark:hover:bg-primary-400;
}

.date-picker-event-calendar
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  .react-datepicker__time-list
  .react-datepicker__time-list-item {
  @apply dark:hover:bg-gray-100 max-sm:my-1 max-sm:!h-[unset] max-sm:!px-1 max-sm:!py-0 max-sm:text-[10px];
}

.react-datepicker__close-icon {
  padding: 0px 15px 0px 0px !important;
  font-size: 20px !important;
}

.react-datepicker__close-icon::after {
  width: 20px !important;
  height: 20px !important;
}
