#dp {
    height: 80vh !important;
}

.scheduler_default_main {
    border: 0px !important;
    border-radius: 0.375rem !important;
}

.scheduler_default_rowheader_inner_indent {
    margin-left: 0px !important;
    width: 100% !important;
}

.scheduler_default_rowheader_inner_text {
    font-size: 1.2em;
}

.scheduler_default_timeheadergroup_inner {
    background-color: #ffffff !important;
}

.scheduler_default_timeheadercol_inner {
    border-right: none !important;
    border-top: none !important;
}

.timeline_today {
    background-color: #ffde7a !important;
}

.scheduler_default_corner_inner {
    background-color: #ffffff !important;
}

.scheduler_default_event {
    border-radius: 5px;
}

.scheduler_default_event_inner {
    padding-left: 10px !important;
    opacity: 0.8;
    color: #000000;
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase;
}

.scheduler_default_event_bar_inner {
    height: 6px !important;
}

.scheduler_default_cell_disabled {
    opacity: 0.4;
}