/*********************************************************
 * React Big Calendar Styles
 ********************************************************/

@import "react-big-calendar/lib/css/react-big-calendar.css";
@import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

.rbc-calendar-zoom {
  zoom: 80%;
}
.rbc-month-view {
  @apply border-muted;
}

.rbc-header {
  @apply h-12 border-muted py-3.5 text-[13px] font-semibold uppercase text-gray-400 lg:px-4;
}

:is(
    .rbc-header + .rbc-header,
    .rbc-day-bg + .rbc-day-bg,
    .rbc-month-row + .rbc-month-row,
    .rbc-timeslot-group
  ) {
  @apply border-muted;
}

.rbc-day-bg {
  @apply sm:px-2;
}

.rbc-date-cell {
  @apply p-2 pb-1 text-left text-sm font-medium text-gray-700;
}

.rbc-row-segment {
  @apply px-1 pb-0.5 ;
}

.rbc-event-content {
    font-size: 11px;
    padding: 0px;
}

.rbc-day-bg.rbc-off-range-bg {
  @apply bg-gray-50;
}

.rbc-off-range {
  @apply text-gray-400;
}

.rbc-time-header.rbc-overflowing,
.rbc-time-view,
.rbc-time-content,
.rbc-time-header-content {
  @apply border-t border-muted;
}

.rbc-today {
  @apply bg-blue-lighter/10;
}

.rbc-day-slot .rbc-time-slot,
.rbc-timeslot-group {
  @apply border-muted dark:!border-gray-400;
}

.rbc-day-slot .rbc-time-slot {
  @apply border-t-0;
}

.rbc-day-slot .rbc-event {
  @apply !h-auto border-0 p-0;
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
  @apply rounded-md bg-primary px-2 py-0.5 text-sm font-medium;
}

.rbc-time-view .rbc-row {
  @apply h-12;
}

.rbc-time-content > * + * > * {
  @apply border-l border-muted;
}
.rbc-timeslot-group {
  @apply border-b border-muted;
}

.rbc-time-header.rbc-overflowing {
  @apply !mr-0 border-r border-transparent lg:mr-3.5;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  flex-flow: column;
}

.rbc-time-header-content .rbc-row-content {
  @apply mt-5;
}

.rbc-time-header-gutter {
  @apply !w-20 !max-w-full;
}

.rbc-time-gutter .rbc-timeslot-group {
  @apply px-3.5 py-2 font-medium text-gray-900;
}

.rbc-timeslot-group {
  @apply font-medium text-gray-900;
}

.rbc-label {
  @apply px-2;
}

.rbc-day-slot .rbc-time-slot + .rbc-time-slot {
  @apply border-t border-muted;
}

.rbc-timeslot-group {
  @apply min-h-[80px] border-muted;
}
.rbc-toolbar {
  @apply mb-0 rounded-t-md border border-b-0 border-muted px-4 py-6;
}

.rbc-btn-group {
  @apply rounded-md border border-muted;
}

.rbc-btn-group button {
  @apply !m-0 !rounded-md border-0 text-sm font-medium text-gray-500 shadow-none hover:bg-white hover:text-gray-800 focus:bg-white active:bg-white active:shadow-none dark:hover:bg-gray-100 dark:focus:bg-gray-100 dark:focus:text-white dark:active:bg-gray-100 dark:active:text-white;
}

.rbc-toolbar button.rbc-active {
  @apply border-primary bg-primary text-primary-foreground shadow-none;
}

.rbc-toolbar-label {
  @apply font-lexend text-xl font-medium text-gray-900 dark:text-white;
}

.rbc-agenda-view {
  @apply border;
}

.rbc-agenda-view,
.rbc-agenda-view table.rbc-agenda-table,
.rbc-agenda-view table.rbc-agenda-table thead > tr > th,
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  @apply border-muted;
}

.rbc-agenda-view table.rbc-agenda-table {
  @apply border-0 border-b;
}

.rbc-agenda-empty {
  @apply p-4 text-center;
}
